import React , { useState, useEffect, useContext, useCallback } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const DateRange = ({ start_date_raw, deadline_raw, taskDuration, handleDateRangeChange, handleDurationChange }) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(start_date_raw);
  const [deadline, setDeadline] = useState(deadline_raw);
  const [duration, setDuration] = useState(taskDuration);
  const [localDuration, setLocalDuration] = useState(taskDuration);

  // Debounced function to handle duration changes
  const debouncedHandleDurationChange = useCallback(
    debounce((value) => {
      handleDurationChange(value);
    }, 500),
    [handleDurationChange]
  );

  const handleDurationInputChange = (e) => {
    const value = e.target.value;
    setLocalDuration(value);
    debouncedHandleDurationChange(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleDateRangeChange(date, deadline);
  };

  const handleDeadlineChange = (date) => {
    setDeadline(date);
    handleDateRangeChange(startDate, date);
  };

  const fetchSomething = () => {
    // axios.get(`/api/`)
    // .then(function(response){
    // //   console.log(response);
    //   if(response.data.success){

    //   } else {

    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured")
    //   reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Task Timeline</h4>
      <p className="modal-description">Select start date and deadline for your task</p>

      <div className="field mt-25">
        <div>
          <label>Start date</label>
        </div>
        <DatePicker 
          selected={startDate}
          onChange={handleStartDateChange}
          className="form-control width-300-important"
          placeholderText="Select start date"
        />
      </div>

      <div className="field mt-25">
        <div>
          <label>Deadline</label>
        </div>
        <DatePicker 
          selected={deadline}
          onChange={handleDeadlineChange}
          className="form-control width-300-important"
          placeholderText="Select deadline"
          minDate={startDate}
        />
      </div>

      {/* <div className="field">
        <label>Estimated hours</label>
        <div class="input-group" style={{width: "300px"}}>
          <input 
            type="text" 
            class="form-control" 
            value={localDuration} 
            onChange={handleDurationInputChange}
          />
          <span class="input-group-addon">Hours</span>
        </div>
      </div> */}

      {/* <div className="field">
        Calendar duration: <span className="badge badge-purple">{Math.ceil((new Date(dateRange[1]) - new Date(dateRange[0])) / (1000 * 60 * 60 * 24))} days</span>
      </div>

      <div className="field">
        Estimated workload: <span className="badge badge-purple">{localDuration} hours</span>
      </div> */}
    </React.Fragment>
  )
}

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default DateRange;