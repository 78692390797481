import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";

const UpdateTitle = ({task, closeModal, refetchData, project_id}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(task.title);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    // Perform any actions needed when editing is finished
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      title: title
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label>Title</label>
          <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)}/>
        </div>

        <div className="text-right">
          <button disabled={disabled} className="btn btn-primary" type="submit">{t("Update")}</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default UpdateTitle;