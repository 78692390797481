import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom';
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import AppContext from '../../Shared/AppContext.js';
import Field from './Field.js';

// ... existing code ...

const Section = ({section, setSections}) => {
  const { notice } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  // ... existing code ...

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
      .then(function(response){
        if(response.data.success){
          // Handle success
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error);
        notice("An error occurred");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
  }

  return (
    <React.Fragment>
      <div className="section-wrapper mb-15 box-shadow background-3 field mb-10 border-all default-padding mb-10 border-radius" key={section.token}>
        <div className="form-header-input">{section.title}</div>
        <div className="form-subheading-input mb-30">
          {section.description}
        </div>
        {section.fields?.filter(item => !["line", "header"].includes(item.type)).map(field => (
          <div className="task-form-edit mt-10" key={field.id}>
            <div class="display-flex gap-8 mb-10">{field.label}</div>
            <Field field={field} setSections={setSections}/>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default Section;