import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));

const NewOrganizationProjectUserProfile = ({user, refetchData, closeModal}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [hourlyRate, setHourlyRate] = useState(user.organization_project_user_profile?.hourly_rate || null)
  const [jobTitle, setJobTitle] = useState(user.organization_project_user_profile?.job_title || null)

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/organization_project_user_profiles`, {
      user_id: user.user_id,
      hourly_rate: hourlyRate,
      job_title: jobTitle
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/organization_project_user_profiles/${user.organization_project_user_profile?.token}`, {
      hourly_rate: hourlyRate,
      job_title: jobTitle
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div className="alert-warning default-padding mb-15">
        This is only visible to you and used in calculating timesheets. <a href="#" target="_blank">Video guide</a>
      </div>

      <form onSubmit={user.organization_project_user_profile ? handleUpdate : handleSubmit}>
        <div className="field">
          <label>Job title</label>
          <input type="text" class="form-control" onChange={(e) => setJobTitle(e.target.value)} defaultValue={jobTitle}/>
        </div>

        <div className="field">
          <label>Hourly rate</label>
          <div class="input-group width-200">
            <input type="number" step="0.01" class="form-control" onChange={(e) => setHourlyRate(e.target.value)} defaultValue={hourlyRate}/>
            <span class="input-group-addon">Per hour</span>
          </div>
        </div>

        <div className="field text-right">
          <button type="submit" className="btn btn-primary" disabled={disabled}>{t("Submit")}</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default NewOrganizationProjectUserProfile