import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom';
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../Shared/AppContext.js';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import { PickerInline } from 'filestack-react';

const UploadField = ({field, setSections, setFields}) => {
  const { notice } = useContext(AppContext);
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    console.log(filesUploaded);
    // const filesData = filesUploaded.map((file) => ({ title: file.name, url: file.url }));
    // updateField(field.id, filesUploaded);
} ;

  const updateField = (newValue) => {
    if(setSections){
      setSections(prev => {
        const updatedSections = prev.map(section => {
          if (section.id === field.section_id) {
            const updatedSection = {
              ...section,
              fields: section.fields.map(f => {
                if (f.id === field.id) {
                  return {...f, value: newValue};
                }
                return f;
              })
            };
            return updatedSection;
          }
          return section;
        });

        return updatedSections;
      });
    } else {
      setFields(prev => {
        return prev.map(f => f.id === field.id ? {...f, value: newValue} : f);
      });
    }
  }

  const uploads_list = Array.isArray(field.value) && field.value.map(upload => {
    const filestackId = upload.url.split('/').pop();
    return (
      <div className="display-flex align-items-center justify-content-between padding-8 border-all border-radius mb-5">
        <div className="upload-item-info">
          <a href={upload.url} target="_blank" className="color-1 opacity-7 opacity-10-hover  upload-item-filename">{upload.filename}</a>
        </div>

        <a className="opacity-5 opacity-10-hover color-1" href={`https://cdn.filestackcontent.com/${filestackId}?dl=true`}><i className="fal fa-download"></i></a>
      </div>
    );
  });

  return (
    field &&
    <React.Fragment>
      <div className="field-answer-wrapper">
        {uploads_list}
      </div>

      {/* <a className="btn btn-small color-1 background-hover border-all" onClick={() => setOpen(true)}>
        <i className="fal fa-upload mr-8"></i>Upload
      </a> */}


      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <PickerInline
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            createReplay(res);
          }}
          clientOptions={{
            sessionCache: true
          }}
          pickerOptions={{
            onClose: () => {
              setOpen(false);
            }, 
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 15, 
            storeTo: {
              location: 'gcs',
              path: '/',
              container: 'queue-filestack'
            }
          }}
        />
      </Modal>
    </React.Fragment>
  );
}

export default UploadField;