import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';

const Timer = ({ timesheet, refetchData, taskView }) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [startTime, setStartTime] = useState(timesheet.start_time);
  const [pauseTime, setPauseTime] = useState(timesheet.pause_time);
  const [endTime, setEndTime] = useState(timesheet.end_time);
  const [isActive, setIsActive] = useState(timesheet.is_active);
  const [elapsedTime, setElapsedTime] = useState(timesheet.elapsed_time);
  const [notes, setNotes] = useState(timesheet.notes);

  useEffect(() => {
    setStartTime(timesheet.start_time);
    setPauseTime(timesheet.pause_time);
    setEndTime(timesheet.end_time);
    setIsActive(timesheet.is_active);
    setElapsedTime(timesheet.elapsed_time);
    setNotes(timesheet.notes);
  }, [timesheet]);

  const startTimer = async () => {
    const utcStartTime = new Date().toISOString(); // Convert current local time to UTC
    await setStartTime(utcStartTime);
    setIsActive(true);
  };

  const pauseTimer = async () => {
    const utcPauseTime = new Date().toISOString(); // Convert current local time to UTC
    await setPauseTime(utcPauseTime);
    setIsActive(false);
  };

  const resumeTimer = async () => {
    const currentTime = new Date().getTime(); // Current time in milliseconds
    const newStartTime = new Date(currentTime - elapsedTime).toISOString(); // Calculate the new startTime based on elapsedTime
    await setStartTime(newStartTime);
    setPauseTime(null);
    setIsActive(true);
  };

  const stopTimer = async () => {
    const utcEndTime = new Date().toISOString(); // Convert current local time to UTC
    await setEndTime(utcEndTime);
    setIsActive(false);
  };

  const formatTime = (time) => {
    if (!time) return '00:00:00';

    const hours = String(new Date(time).getUTCHours()).padStart(2, '0');
    const minutes = String(new Date(time).getUTCMinutes()).padStart(2, '0');
    const seconds = String(new Date(time).getUTCSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    // Setup interval to update elapsed time when the timer is active
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setElapsedTime(new Date() - new Date(startTime));
      }, 1000);
    } else {
      clearInterval(interval); // Clear the interval when isActive becomes false
    }

    return () => clearInterval(interval); // Cleanup function to clear interval on unmount or when isActive changes
  }, [isActive, startTime]);

  useEffect(() => {
    async function updateTimesheet() {
      setDisabled(true)
      try {
        await axios.put(`/api/o/${match.params.organization_id}/timesheets/${timesheet.token}`, {
          start_time: startTime,
          pause_time: pauseTime,
          end_time: endTime,
          is_active: isActive,
          elapsed_time: elapsedTime
        });

        refetchData();
      } catch (error) {
        console.log(error);
        notice("An error occurred");
      } finally {
        setDisabled(false);
      }
    }

    if (startTime !== timesheet.start_time ||
      pauseTime !== timesheet.pause_time ||
      endTime !== timesheet.end_time ||
      isActive !== timesheet.is_active ||
      elapsedTime !== timesheet.elapsed_time) {
      updateTimesheet();
    }
  }, [isActive]);

  const handleSubmit = async () => {
    setDisabled(true);

    try {
      await axios.put(`/api/o/${match.params.organization_id}/timesheets/${timesheet.token}`, {
        notes: notes
      });

      notice("Updated successfully");
      refetchData();
    } catch (error) {
      console.log(error);
      notice("An error occurred");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    } finally {
      setDisabled(false);
    }
  };

  const markFinished = async () => {
    const confirmThis = window.confirm("Are you sure you want to submit this timesheet?");
    if (confirmThis) {
      try {
        await axios.post(`/api/o/${match.params.organization_id}/timesheets/${timesheet.token}/mark_finished`, {
          notes: notes
        });

        notice("Marked as complete!");
        refetchData();
      } catch (error) {
        console.log(error);
        notice("An error occurred");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      } finally {
        setDisabled(false);
      }
    }
  };

  const deleteTimesheet = async () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'This will permanently delete it. So proceed with caution.',
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: async () => {
            try {
              await axios.delete(`/api/o/${match.params.organization_id}/timesheets/${timesheet.token}`);
      
              refetchData();
            } catch (error) {
              console.log(error);
              notice("An error occurred");
              reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
            } finally {
              setDisabled(false);
            }
          }
        },
      ]
    });
  };

  return (
    <React.Fragment>
      <li className={`daas-platform-links-li animate color-1 ${taskView ? "no-margin-important" : ""}`}>
        <div className={`btn-group daas-platform-links-li width-100-percent ${taskView ? "no-margin-important" : ""}`} style={{ padding: taskView ? "5px 9px" : "0px 0px"  }}>
          <a className={`daas-platform-links-a dropdown-toggle ${taskView ? "color-1 border-all no-margin-important" : "sidebar-text-color"}`} data-toggle="dropdown">
              <>
                {disabled ? (
                    <i className="fas fa-spinner fa-spin color-1" style={{ fontSize: "20px", marginLeft: "-3px", marginRight: "8px" }}></i>
                ):(
                  <>
                    {(startTime == null) &&
                      <i disabled={disabled} onClick={startTimer} style={{ fontSize: "22px", marginLeft: "-3px", marginRight: "8px" }} className="fas fa-play-circle color-green mr-10"></i>
                    }

                    {(isActive && !pauseTime) &&
                      <i disabled={disabled} onClick={pauseTimer} style={{ fontSize: "22px", marginLeft: "-3px", marginRight: "8px" }} className="fas fa-pause-circle color-red mr-10"></i>
                    }

                    {(startTime != null && !isActive) &&
                      <i disabled={disabled} onClick={resumeTimer} style={{ fontSize: "22px", marginLeft: "-3px", marginRight: "8px" }} className="fas fa-play-circle color-green mr-10"></i>
                    }
                  </>
                )}
              </>
            {formatTime(new Date(elapsedTime))}
          </a>

          <ul className={`dropdown-menu animated fadeInUp pull-right`} role="menu" style={{ background: "white" }} onClick={(e) => e.stopPropagation()}>
            <div className="default-padding active-timer-trackers-wrapper">
              <p className="opacity-4">Active Timer</p>
              <h1 className="no-margin opacity-6 font-weight-400">
                {formatTime(new Date(elapsedTime))}
              </h1>

              <div className="active-timer-dropdown-icon">
              {disabled ? (
                <i className="fas fa-spinner fa-spin opacity-4 text-black-only"></i>
              ):(
                <>
                  {(startTime == null) &&
                    <>
                      <i onClick={startTimer} className="fas fa-play-circle color-green grow mr-8 animated fadeIn"></i>
                    </>
                  }

                  {(isActive && !pauseTime) &&
                    <i onClick={pauseTimer} className="fas fa-pause-circle color-red grow mr-8 animated fadeIn"></i>
                  }

                  {(startTime != null && !isActive) &&
                    <i onClick={resumeTimer} className="fas fa-play-circle color-green grow mr-8 animated fadeIn"></i>
                  }
                </>
              )}
              </div>

              <div className="active-timer-info">
                <div className="field">
                  <Link className="badge active-timer-task width-200 truncate" to={`/o/${match.params.organization_id}/projects/${timesheet.project.token}/tasks?task_id=${timesheet.task.token}`}>{timesheet.task.title}</Link>
                </div>

                <div className='field'>
                  <textarea defaultValue={notes} onChange={(e) => setNotes(e.target.value)} className="form-control form-control-dropdown" placeholder="Write your notes..." rows="4"></textarea>
                </div>

                <div className="text-right">
                  <a onClick={deleteTimesheet} className="btn color-red btn-small">Delete</a>
                  <a className="btn btn-primary btn-small" disabled={disabled} onClick={handleSubmit}>Update</a>
                </div>
              </div>
            </div>

            <div className="active-timer-submit" onClick={markFinished}>
              <i className="fal fa-alarm-plus mr-8"></i>Submit timesheet
            </div>
          </ul>
        </div>
      </li>
    </React.Fragment>
  )
}

export default Timer;
