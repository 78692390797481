import React , { useState, useContext, useRef } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { PickerOverlay, PickerInline } from 'filestack-react';
import { Modal } from 'react-responsive-modal';
import LoomRecorder from "./LoomRecorder.js";
import ReactTooltip from 'react-tooltip';
import Mention from '@tiptap/extension-mention'
import { EditorContent, useEditor, ReactRenderer, BubbleMenu } from '@tiptap/react'
import FileHandler from '@tiptap-pro/extension-file-handler'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import MentionList from './MentionList.jsx'
import tippy from 'tippy.js'
import Placeholder from '@tiptap/extension-placeholder'
import Image from '@tiptap/extension-image'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Underline from '@tiptap/extension-underline'
import Commands from './commands.js'
import getSuggestionItems from "./items.js";
import renderItems from "./renderItems.js";
import TipTapBubbleMenu from "../../../../Shared/TipTapBubbleMenu.js";
import Highlight from '@tiptap/extension-highlight'
import {ProjectContext} from "../../../../Projects/ProjectContext.js";
import { useTranslation } from 'react-i18next';
import {OrganizationContext} from "../../../../Shared/OrganizationContext.js";
import { Extension } from '@tiptap/core'
import { useHotkeys } from 'react-hotkeys-hook'

const Form = ({task, refetchData, projectAuthorizations, project_id, users, taskComment, closeModal, parent_id, setTaskComments, setCommentReply}) => {
  const {hasPermission} = useContext(OrganizationContext)
  const {testers} = useContext(ProjectContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [comment, setComment] = useState(taskComment != null ? taskComment.comment : (localStorage.getItem(`task_comment_${task.token}`) || ""));
  const [isPrivate, setIsPrivate] = useState(taskComment != null ? taskComment.private : ((projectAuthorizations.project_user || !hasPermission("private_task")) ? true : (localStorage.getItem('taskCommentPrivacy') ? true : false)));
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const { t } = useTranslation();
  const [creatingReplay, setCreatingReplay] = useState(false);
  const submitButtonRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent the default behavior, which may not allow dropping.
    setOpen(true);
  };

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    setFiles(prevFiles => [...prevFiles, ...filesUploaded]);
  }

  // Create task_comment
  const submitComment = (evt) => {
    evt.preventDefault();
    handleSubmitComment();
  }

  const handleSubmitComment = () => {
    if (disabled) return;
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments`, {
      comment: comment, 
      private: isPrivate, 
      loom_link: videoUrl,
      parent_id: parent_id, 
      // files: files
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        if(response.data.task_comment.parent_id === null){
          setTaskComments(oldArray => [...oldArray, {...response.data.task_comment, uploadingReplay: files.length > 0}]);
        } else {
          setTaskComments((oldArray) =>
            oldArray.map((taskComment) => {
              if (taskComment.id === response.data.task_comment.parent_id) {
                return {
                  ...taskComment,
                  nested_task_comments: [...taskComment.nested_task_comments, {...response.data.task_comment, uploadingReplay: files.length > 0}],
                };
              }
              return taskComment;
            })
          );
        }
        uploadReplays(response.data.task_comment_id);
        setComment("");
        localStorage.removeItem(`task_comment_${task.token}`);
        editor.commands.clearContent();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const updateComment = (evt) => {
    evt.preventDefault();
    handleUpdateComment();
  }

  const handleUpdateComment = () => {
    if (disabled) return;
    setDisabled(true);
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${taskComment.token}`, {
      comment: comment, 
      private: isPrivate, 
      loom_link: videoUrl
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        uploadReplays(taskComment.token);
        refetchData();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const uploadReplays = (task_comment_id) => {
    setCreatingReplay(true);
    
    axios.post(`/api/replays`, {
      files: files,
      filestack: true, 
      project_id: (project_id || match.params.project_id), 
      folder_id: null
    })
    .then(function(response){
      // console.log(response)
      if(response.data.success){
        createTaskCommentReplay(task_comment_id, response.data.replays)
        setFiles([])
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occurred")
      setDisabled(false)
    })
    .then(function () {
      setCreatingReplay(false);
    });
  }

  const createTaskCommentReplay = (task_comment_id, replays) => {
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${task_comment_id}/task_comment_replays`, {
      replays: replays
    })
    .then(function(response){
      // console.log(response)
      if(response.data.success){
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
  }

  const removeFile = (handle) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.handle !== handle));
  };

  const files_list = files.map(file => {
    return(
      <div className="file-uploaded-icon hover-parent-opacity animated fadeInUp slow color-1 truncate-parent border-all">
        <i className="fas fa-file mr-8 opacity-4 opacity-10-hover"></i>
        <a className="file-uploaded-icon-filename truncate-child color-1 opacity-6 opacity-10-hover" href={file.url} target="_blank">
          {file.filename}
        </a>
        <i onClick={() => removeFile(file.handle)} className="fas fa-times file-uploaded-icon-times hover-child-opacity"></i>
      </div>
    )
  });

  const editor = useEditor({
    // autofocus: taskComments?.length > 0 ? true: false,
    onUpdate({ editor }) {
      setComment(editor.getHTML())
      if (!parent_id) {
        localStorage.setItem(`task_comment_${task.token}`, editor.getHTML());
      }
    },
    extensions: [
      Extension.create({
        priority: 1000,
        addKeyboardShortcuts() {
          return {
            'Mod-Enter'() {
              if (!disabled && editor.getHTML().length > 0) {
                submitButtonRef.current?.click();
                setDisabled(true);
              }
              return true
            },
            'Ctrl-Enter'() {
              if (!disabled && editor.getHTML().length > 0) {
                submitButtonRef.current?.click();
                setDisabled(true);
              }
              return true
            },
          }
        },
      }),
      StarterKit,
      Image,
      TaskList,
      Underline,
      Highlight.configure({ multicolor: true }),
      TaskItem.configure({
        nested: true,
      }),
      // Youtube.configure({
      //   inline: false,
      //   width: 480,
      //   height: 320,
      // }),
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      FileHandler.configure({
        allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
        onDrop: (currentEditor, files, pos) => {
          files.forEach(file => {
            const fileReader = new FileReader()

            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
              notice("Uploading dropped image...")
              axios.post(`/api/upload_to_digitalocean`, {
                base64: fileReader.result
              })
              .then(function(response){
                if(response.data.success){
                  currentEditor.chain().insertContentAt(pos, {
                    type: 'image',
                    attrs: {
                      src: response.data.image_link,
                      class: 'your-custom-class',
                    },
                  }).focus().run()
                } else {
                  response.data.errors.forEach((error) => {
                    notice(error);
                  });
                }
              })
              .catch(function(error){
                console.log(error)
                notice("An error occured");
                reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
              })
            }
          })
        },
        onPaste: (currentEditor, files, htmlContent) => {
          files.forEach(file => {
            if (htmlContent) {
              return false
            }

            const fileReader = new FileReader()

            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
              notice("Pasting image...")
              axios.post(`/api/upload_to_digitalocean`, {
                base64: fileReader.result
              })
              .then(function(response){
                if(response.data.success){
                  currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
                    type: 'image',
                    attrs: {
                      src: response.data.image_link,
                      class: 'your-custom-class',
                    },
                  }).focus().run()
                } else {
                  response.data.errors.forEach((error) => {
                    notice(error);
                  });
                }
              })
              .catch(function(error){
                console.log(error)
                notice("An error occured");
                reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
              })
              .then(function () {

              });
            }
          })
        },
      }),
      Placeholder.configure({
        placeholder: `/slash for menu. @mention to send notifications. ${navigator.platform.includes('Mac') ? 'CMD' : 'CTRL'}+Enter to submit. `,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention border-all background-3',
        },
        suggestion: {
          items: ({ query }) => {
            return users
              .filter((item) =>
                item.toLowerCase().startsWith(query.toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let reactRenderer;
            let popup;

            return {
              onStart: (props) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor
                });

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start"
                });
              },
              onUpdate(props) {
                reactRenderer.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                });
              },
              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return reactRenderer?.ref?.onKeyDown(props);
              },
              onExit() {
                popup[0].destroy();
                reactRenderer.destroy();
              }
            };
          }
        }
      }),
      Commands.configure({
        suggestion: {
          items: getSuggestionItems,
          render: renderItems
        }
      })
    ],
    content: comment,
  });

  useHotkeys('r', (event) => {
    event.preventDefault();
    editor?.commands.focus();
  }, [editor]);

  return(
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
        <PickerInline
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            createReplay(res);
            setOpen(false);
          }}
          clientOptinos={{
            sessionCache:true
          }}
          pickerOptions={{
            onClose: () => {
              setOpen(false);
            }, 
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 15, 
            storeTo: {
              location: 'gcs',
              path: '/',
              container: 'queue-filestack'
            }
          }}
        />
      </Modal>

      <form id="task-comment-form position-relative" className="field" onSubmit={taskComment != null ? updateComment : submitComment} onDragOver={handleDragOver}>
        {files.length > 0 &&
          <div className="file-uploaded-icon-wrapper">
            {!creatingReplay && 
              <>
                {files_list}
              </>
            }
          </div>
        }
        <div className={`background-input pt-8 border-all border-radius ${isPrivate ? 'private-task-comment' : 'border-all'}`}>
          {(projectAuthorizations.organization_user || projectAuthorizations.project_user) &&
            <div class="btn-group dropup">
                {isPrivate ? (
                  <React.Fragment>
                    <a className="opacity-6 opacity-10-hover btn btn-small private-task-comment-no-border dropdown-toggle mb-5" data-toggle="dropdown" style={{border: "none"}}>
                      <i class="fal fa-lock mr-5"></i>{t("Team only")}<i class="far fa-angle-up ml-10"></i>
                    </a>
                  </React.Fragment>
                ):(
                  <React.Fragment>
                    <a className="opacity-6 opacity-10-hover color-1 btn btn-small dropdown-toggle mb-5" data-toggle="dropdown">
                      <i class="far fa-globe-americas mr-5"></i>{t("Everyone")}<i class="far fa-angle-up ml-10"></i>
                    </a>
                  </React.Fragment>
                )}
              <ul class="dropdown-menu animated fadeInUp" role="menu" style={{left: 15}}>
                <li><a onClick={() => {
                  setIsPrivate(true);
                  localStorage.setItem('taskCommentPrivacy', 'true');
                }}><i class="fal fa-lock mr-5"></i>{t("Team only")}</a></li>
                {hasPermission("private_task") && 
                <li><a onClick={() => {setIsPrivate(false); localStorage.removeItem('taskCommentPrivacy');}}><i class="far fa-globe-americas mr-5"></i>{t("Everyone")}</a></li>
                }
              </ul>
            </div>
          }
          <div className="">
            {/* <Editor value={comment} onChange={(e) => setComment(e.target.value)} containerProps={{ style: { minHeight: 150 } }} onPaste={handlePaste}/> */}
            <EditorContent editor={editor} className={`background-none default-padding tiptap-height-60 pt-0`} style={{paddingBottom: 45}}/>
            {editor && 
              <TipTapBubbleMenu editor={editor}/>
            }
          </div>
        </div>

        <div className="field task-comment-form-button-wrapper">
          <div className="task-comment-form-buttons animated fadeIn">
            <button 
              ref={submitButtonRef}
              disabled={disabled || comment.length == 0 || editor.isEmpty} 
              id="task-comment-form-submit" 
              className="btn btn-primary btn-small" 
              type="submit"
            >
              <i class="far mr-5 fa-paper-plane mr-5"></i> Send
            </button>
            {parent_id && 
              <a className="btn btn-small border-all border-all-secondary color-1 background-hover-2 ml-10 mr-10" onClick={() => {
                setCommentReply(false);
              }}>
                Cancel
              </a>
            }
            
            <a className={`btn btn-small border-all color-1 background-hover ml-10 mr-10 ${isPrivate ? 'private-task-comment' : ''}`} onClick={() => setOpen(true)}>
              <i data-tip={t("Upload")} className="fal fa-link task-comment-replay-icon margin-none"></i>
            </a>

            {taskComment == null && 
              <LoomRecorder classNames={`${isPrivate ? 'private-task-comment opacity-7' : 'opacity-7'}`} setVideoUrl={setVideoUrl} videoUrl={videoUrl}/>
            }

          </div>
          {!editor.isFocused && (
            <div className="task-comment-form-r-keybind  keybind opacity-4">
              R
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  )
}

export default Form
