import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { Command } from 'cmdk';
import axios from 'axios';
import {ProjectContext} from "../Projects/ProjectContext.js";

const CommandMenu = () => {
  const {projects, groups, openCmdk, setOpenCmdk} = useContext(ProjectContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(false)
  const [currentFolder, setCurrentFolder] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [replays, setReplays] = useState([]);
  const [portal, setPortal] = useState(window.location.pathname.includes('/portal/') ? "/portal/" : "/o/");
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    const searchInProjectsAndGroups = () => {
      const projectTitles = projects.map(p => p.title.toLowerCase());
      const groupProjectTitles = groups.flatMap(g => g.projects?.map(p => p.title.toLowerCase()) || []);
      const allTitles = [...projectTitles, ...groupProjectTitles];
      return allTitles.some(title => title.includes(debouncedSearch.toLowerCase()));
    };

    if (debouncedSearch && !searchInProjectsAndGroups()) {
      getItems();
    }
  }, [debouncedSearch, projects, groups]);

  const getItems = async () => {
    try {
      setLoading(true);
      setSearching(true);
      const res = await axios.get(`/api/o/${match.params.organization_id}/command_tasks.json?search=${debouncedSearch}`);
      setTasks(res.data.tasks);
      setReplays(res.data.replays);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
      setSearching(false);
    }
  };

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpenCmdk((openCmdk) => !openCmdk);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const projects_list = projects?.map((project) => {
    return (
      <Command.Item 
        key={`command-project-${project.token}`} 
        value={`${project.title} ${project.token}`}
        onSelect={() => {
          setOpenCmdk(false);
          history.push(`${portal}${match.params.organization_id}/projects/${project.token}/tasks`);
        }}
      >
        <div className="display-flex flex-direction-column">
          <div>
            <img src={project.avatar} className="command-project-avatar" />
            {project.title}
          </div>
        </div>
      </Command.Item>
    )
  });

  const groups_list = groups?.map((group) => {
    return (
      <Command.Group heading={group.title}>
        {group.projects?.map((project) => (
          <Command.Item 
            key={`command-group-project-${project.token}`} 
            value={`${project.title} ${project.token}`}
            onSelect={() => {
              setOpenCmdk(false);
              history.push(`${portal}${match.params.organization_id}/projects/${project.token}/tasks`);
            }}
          >
            <div className="display-flex flex-direction-column pl-20 ml-10">
              <div>
                <img src={project.avatar} className="command-project-avatar" />
                {project.title}
              </div>
            </div>
          </Command.Item>
        ))}
      </Command.Group>
    )
  });

  return (
    <Command.Dialog open={openCmdk} onOpenChange={setOpenCmdk} label="Global Command Menu">
      <Command.Input 
        value={search}
        onValueChange={setSearch}
        placeholder={currentFolder ? `In folder: ${currentFolder}` : "Search for tasks, files, and clients. (Beta)"} 
      />
      <Command.List>
        <Command.Empty>
          {searching ? (
            <>
              <i className="fas fa-spinner fa-spin mr-15"/>Searching...
            </>
          ):(
            "No results found."
          )}
        </Command.Empty>

        {debouncedSearch && groups_list}
        <Command.Group heading="Clients">
          {projects_list}
        </Command.Group>

        {debouncedSearch && (
          <>
            <Command.Group heading="Tasks">
              {searching && <Command.Loading>Searching tasks...</Command.Loading>}
              {!searching && loading && <Command.Loading>Fetching tasks...</Command.Loading>}
              {!searching && !loading && tasks.map((item) => {
                return (
                  <Command.Item 
                    key={`task-${item.token}`} 
                    value={`${item.title} ${item.token}`}
                    onSelect={() => {
                      setOpenCmdk(false);
                      history.push(`${portal}${match.params.organization_id}/projects/${item.project.token}/tasks?task_id=${item.token}`);
                    }}
                  >
                    <div className="display-flex flex-direction-column">
                      <div className="font-10 mb-5 opacity-6">{item.project.title}</div>
                      <div>{item.title}</div>
                    </div>
                  </Command.Item>
                )
              })}
            </Command.Group>

            <Command.Group heading="Replays">
              {searching && <Command.Loading>Searching replays...</Command.Loading>}
              {!searching && loading && <Command.Loading>Fetching replays...</Command.Loading>}
              {!searching && !loading && replays.map((item) => {
                return (
                  <Command.Item 
                    key={`replay-${item.token}`} 
                    value={`${item.title} ${item.token}`}
                    onSelect={() => {
                      setOpenCmdk(false);
                      history.push(`/replays/${item.token}`);
                    }}
                  >
                    <div className="display-flex flex-direction-column">
                      <div>{item.title}</div>
                    </div>
                  </Command.Item>
                )
              })}
            </Command.Group>
          </>
        )}
      </Command.List>

      <p className="font-10 opacity-6 text-center pt-5 pb-5">
        Press escape to close
      </p>
    </Command.Dialog>
  );
};

export default CommandMenu;
